(function ($) {
	"use strict";
	$(document).ready(function() {
		var location = $("#thim-weather").attr("data-location");
		var unit = $("#thim-weather").attr("data-unit");
		var today = $("#thim-weather").attr("data-today")
		$.simpleWeather({
			location: location,
			woeid: '',
			unit: unit,
			success: function(weather) {
				var html;
				html = '<div class="content"><span class="name">'+ today +'</span> <span class="unit color">'+weather.temp+'&deg;'+weather.units.temp+'</span> <i class="icon-'+weather.code+'"></i></div>';
				html += '<ul>';
				for(var i=1;i<4;i++) {
					html += '<li><span class="name">'+weather.forecast[i].day+':</span> <span class="unit color">'+weather.forecast[i].high+'&deg;'+weather.units.temp+ '</span> <i class="icon-'+weather.forecast[i].code+'"></i></li>';
				};
				html += '</ul>';

				$("#thim-weather").html(html);
			},
			error: function(error) {
				$('#thim-weather').html("Could not get Weather data");
			}

		});
	});

})(jQuery);

