(function ($) {
    "use strict";

    $(document).ready(function () {
        //Background video
        $('.video-play').on( "click", function () {
            $('.cover-image').css('display','none');
            var elem = $(this),
                video = $(this).parents('.thim-sc-video').find('.full-screen-video'),
                player = video.get(0);
            if (player.paused) {
                player.play();
                elem.removeClass('ion-ios-play');
                elem.addClass('ion-ios-pause');
            } else {
                player.pause();
                elem.removeClass('ion-ios-pause');
                elem.addClass('ion-ios-play');
            }
        });
    });

})(jQuery);