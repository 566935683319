(function ($) {
    "use strict";

    $(document).ready(function () {
        $('.list-icon-box').owlCarousel({
            loop:false,
            margin:10,
            nav: false,
            responsive:{
                0:{
                    items:1
                },
                600:{
                    items:2
                },
                1000:{
                    items:4
                }
            }
        });
    });

})(jQuery);