(function ($) {
    "use strict";

    $(window).load(function () {
        var $our_gallery = $(".thim-our-gallery");

        $our_gallery.each(function () {
            var $element = $(this);

            var nav = ($element.attr('data-nav') === 'true') ? true : false;
            var dots = ($element.attr('data-dots') === 'true') ? true : false;
            var item = $element.attr('data-visible');
            var auto = $element.attr('data-auto');
            var res_480 = 2;
            var res_640 = 3;
            var res_768 = 4;
            var res_1024 = 5;

            res_480 = (item < 2) ? 1 : 2;
            res_640 = (item > 3) ? 3 : item;
            res_768 = (item > 4) ? 4 : item;
            res_1024 = (item > 5) ? 5 : item;
            var language = [
                thim_languages.prev,
                thim_languages.next
            ];

            $element.owlCarousel({
                margin: 15,
                mouseDrag: true,
                touchDrag: true,
                //items: item,
                nav: nav,
                navText: language,
                dots: dots,
                loop: true,
                lazyLoad: true,
                autoplay: true,
                autoplayTimeout: auto,
                autoplayHoverPause: true,

                responsive: {
                    0: {
                        items: 1,
                        dots: true,
                    },
                    480: {
                        items: res_480,
                        dots: true,
                    },
                    640:{
                        items: res_640,
                    },
                    768:{
                        items: res_768,
                    },
                    1024: {
                        items: res_1024,
                    },
                    1200: {
                        items: item
                    }
                }

            });
        });

        var $our_gallery_post = $(".thim-our-gallery .item .image");

        $our_gallery_post.each(function (){
            var dragged = 0;
            var threshold = 2;

            $(this).on('mousedown', function () {
                dragged = 0;
            });

            $(this).on('mousemove', function () {
               dragged++;
            });


            $(this).on('click', function (event) {
                if(dragged < threshold){
                    var count = $(this).attr('data-count');
                    if ( count != 0 ) {
                        event.preventDefault();
                    } else return;
                    $(this).addClass('loading');
                    var current_post_id = $(this).attr('data-post');
                    $.ajax({
                        type: "POST",
                        url: ajaxurl,
                        data: {
                            action: 'thim_sc_our_gallery',
                            post_id: current_post_id
                        },
                        success: function (res) {
                            $.magnificPopup.open({
                                tClose: 'Close (Esc)',
                                tLoading: 'Loading...',
                                items:  res.data,
                                type: 'image',
                                gallery:{
                                    enabled:true
                                }
                            });

                            setTimeout(function () {
                                $(".thim-our-gallery .item .image").removeClass('loading');
                            }, 2000);

                        }
                    });
                }


            });
        });
    });
})(jQuery);